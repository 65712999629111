import React from "react"
import { Link, graphql } from "gatsby"
import {
  StyledBlogHeader,
  BlogPagination,
  PaginationPageNumber,
  PaginationList,
  CurrentPageNumber,
} from "../styledComponents/blogArchive"
import ShareTools from "../components/socialLinks"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogList from "../components/BlogList"
import TagCloud from "../components/TagCloud"

const TagIndex = ({
  data,
  pageContext: {
    nextPagePath,
    previousPagePath,
    humanPageNumber,
    numberOfPages,
    tag,
    skip,
  },
}) => {
  const posts = data.allWpPost.nodes
  const breakpoints = useBreakpoint()

  if (!posts.length) {
    return (
      <Layout>
        <SEO title="All posts" />
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout displayNavBorder>
      <SEO
        title={`Blog posts tagged by ${tag}`}
        description={data.allWpPost.nodes[0].categories.nodes[0].description}
      />
      <StyledBlogHeader>
        <h1>Blogs tagged as '{tag}'</h1>
        <ShareTools shareTitle="Follow me" page={`Blogs : ${tag}`}/>
      </StyledBlogHeader>
      <BlogList posts={posts} />
      <Pagination>
        {previousPagePath ? (
          <Link to={previousPagePath} className="previousPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </Link>
        ) : (
          <span className="previousPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </span>
        )}

        {(breakpoints.xs || breakpoints.sm) && (
          <CurrentPageNumber isCurrentPage>{humanPageNumber}</CurrentPageNumber>
        )}

        <PaginationList>
          {Array.from({ length: numberOfPages }, (_, i) => (
            <PaginationPageNumber isCurrentPage={i === humanPageNumber - 1}>
              <Link
                key={`pagination-number${i + 1}`}
                to={`/blog/tags/${tag.replace(/\s+/g, '-').toLowerCase()}/${i === 0 ? "" : i + 1}`}
              >
                {i + 1}
              </Link>
            </PaginationPageNumber>
          ))}
        </PaginationList>

        {nextPagePath ? (
          <Link to={nextPagePath} className="nextPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </Link>
        ) : (
          <span className="nextPageButton">
            <svg
              width="25"
              height="25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16.536 8.35V7.128a.126.126 0 00-.204-.1l-7.137 5.575a.505.505 0 000 .796l7.137 5.575a.126.126 0 00.204-.1V17.65a.256.256 0 00-.096-.2l-5.7-4.448 5.7-4.45a.256.256 0 00.096-.2z" />
            </svg>
          </span>
        )}
      </Pagination>
      <TagCloud />
    </Layout>
  )
}

export default TagIndex

const Pagination = ({ children }) => {
  return <BlogPagination>{children}</BlogPagination>
}

export const pageQuery = graphql`
  query WordPressTagArchive($tag: String!, $skip: Int!, $limit: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { tags: { nodes: { elemMatch: { name: { eq: $tag } } } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
  }
`
